import { 
    FETCH_TESTIMONIALS, FETCH_TESTIMONIALS_DONE, SET_TESTIMONIALS,
    ADD_TESTIMONIAL, ADD_TESTIMONIAL_DONE,

    FETCH_VID_TESTIMONIALS, FETCH_VID_TESTIMONIALS_DONE, SET_VID_TESTIMONIALS
} from './actions';

import {
    INIT_STATUS, FETCHING, FETCH_DONE
} from './../../config/constants';

const initState = {
    testimonials         : {},
    videos               : {},
    fetchStatus          : INIT_STATUS,
    fetchVidStatus       : INIT_STATUS,
    addTestimonialSuccess: null,
    addVidSuccess        : null
};

const testimonial = ( state = initState, { type, payload } ) => {
    let retState = state;

    switch( type ) {
        case FETCH_TESTIMONIALS :
        case ADD_TESTIMONIAL : {
            retState = {
                ...retState,
                fetchStatus : FETCHING
            }
            break;
        }

        case FETCH_TESTIMONIALS_DONE : {
            retState = {
                ...retState,
                fetchStatus : FETCH_DONE
            }
            break;
        }

        case SET_TESTIMONIALS : {
            const { testimonials } = payload;

            retState = {
                ...retState,
                testimonials
            }
            break;
        }

        case ADD_TESTIMONIAL_DONE : {
            const { success } = payload;

            retState = {
                ...retState,
                fetchStatus          : FETCH_DONE,
                addTestimonialSuccess: success
            }
            break;
        }

        case FETCH_VID_TESTIMONIALS : {
            retState = {
                ...retState,
                fetchVidStatus : FETCHING
            }
            break;
        }

        case FETCH_VID_TESTIMONIALS_DONE : {
            retState = {
                ...retState,
                fetchVidStatus : FETCH_DONE
            }
            break;
        }

        case SET_VID_TESTIMONIALS : {
            const { videos } = payload;

            retState = {
                ...retState,
                videos
            }
            break;
        }

        default : {
            retState = {
                ...retState
            }
            break;
        }
    }
    return retState;
};

export default testimonial;