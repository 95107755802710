import {
    all, takeLatest, call, put
} from 'redux-saga/effects';
import UserActions, {
    FETCH_USERS, DELETE_USER, SAVE_USER,
    CHANGE_PASS
} from './actions';
import User from '../../../api/User';
import AlertActions from './../../alert/action';
import SessionActions from './../../session/actions';

function* logout() {
    yield put( SessionActions.doLogout() );
}

function* fetchUsers() {
    try {
        const { success = false, message = '', data = []} = yield call([ User, User.getUsers ]);


        yield put ( UserActions.fetchUsersDone() );

        if( !success ) {
            if( message === 'Unauthorized') {
                yield call( logout );
            }
            yield put( AlertActions.addError( message ) );
        } else {
            const users = data.reduce(( accumulator, record ) => ({
                ...accumulator,
                [ record.id ] :{
                    ...record
                }
            }),{});

            yield put( UserActions.setUsers( users ));
        }
    } catch ( error ) {
        yield put ( UserActions.fetchUsersDone() );
        yield put( AlertActions.addError( error ) );
    }
}

function* deleteUser({payload}) {
    try {
        const { id } = payload;
        const { success = false, message = ''} = yield call([ User, User.deleteUser ], id );

        if( !success ) {
            if( message === 'Unauthorized') {
                yield call( logout );
            }
            yield put ( UserActions.deleteUserDone( false ) );
            yield put( AlertActions.addError( message ) );
        } else {
            yield put ( UserActions.deleteUserDone( true ) );
            yield call( fetchUsers );
        }
    } catch ( error ) {
        yield put ( UserActions.deleteUserDone( false ) );
        yield put( AlertActions.addError( error ) );
    }
}

function* saveUser({payload}) {
    try {
        const { params } = payload;
        const { success = false, message = ''} = yield call([ User, User.saveUser], params );

        if( !success ) {
            if( message === 'Unauthorized') {
                yield call( logout );
            }
            yield put ( UserActions.saveUserDone( false ) );
            yield put( AlertActions.addError( message ) );
        } else {
            yield put ( UserActions.saveUserDone( true ) );
            yield call( fetchUsers );
        }
    } catch ( error ) {
        yield put ( UserActions.saveUserDone( false ) );
        yield put( AlertActions.addError( error ) );
    }
}

function* changePassword({payload}) {
    try {
        const { params } = payload;
        const { success = false, message = ''} = yield call([ User, User.changePassword], params );

        if( !success ) {
            if( message === 'Unauthorized') {
                yield call( logout );
            }
            yield put ( UserActions.changePassDone( false ) );
            yield put( AlertActions.addError( message ) );
        } else {
            yield put ( UserActions.changePassDone( true ) );
            yield put( AlertActions.addSuccess( message ) );
        }
    } catch ( error ) {
        yield put ( UserActions.changePassDone( false ) );
        yield put( AlertActions.addError( error ) );
    }
}

function* watchUser() {
    yield takeLatest( FETCH_USERS, fetchUsers );
    yield takeLatest( DELETE_USER, deleteUser );
    yield takeLatest( SAVE_USER, saveUser );
    yield takeLatest( CHANGE_PASS, changePassword );
}

export default function* userRoot() {
    yield all([
        call( watchUser )
    ]);
}