import HTTP from '../util/HTTP';

class User {
    getUsers( ) {
        const url = `/user.php`;

        const params = {
            m : 'getUsers'
        }

        return HTTP.post( url, params ,'getUsers');
    }

    deleteUser( id ) {
        const url = `/user.php`;

        const params = {
            id,
            m : 'deletedUser'
        }

        return HTTP.post( url, params ,'deletedUser');
    }

    saveUser( param ) {
        const url = `/user.php`;

        const params = {
            ...param,
            m : 'saveUser'
        }

        return HTTP.post( url, params ,'saveUser');
    }

    changePassword( param ) {
        const url = `/user.php`;

        const params = {
            ...param,
            m : 'changePassword'
        }

        return HTTP.post( url, params ,'changePassword');
    }
}

export default new User();