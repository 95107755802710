import { createSelector } from 'reselect';

const userState = state => state.user;

export const getUserInfo = createSelector(
    userState,
    user => user.userInfo || JSON.parse(localStorage.getItem('inf'))
);

export const getUsers = createSelector(
    userState,
    user => user.users
);

export const getFetchStatus = createSelector(
    userState,
    user => user.fetchStatus
);

export const getUpdateStatus = createSelector(
    userState,
    user => user.updateStatus
);

export const getSaveSuccessStatus = createSelector(
    userState,
    user => user.saveSuccessStatus
);

export const getDeleteSuccessStatus = createSelector(
    userState,
    user => user.deleteSuccessStatus
);

export const getChangePassStatus = createSelector(
    userState,
    user => user.changePassStatus
);

export const getChangePassSuccessStatus = createSelector(
    userState,
    user => user.changePassSuccessStatus
);