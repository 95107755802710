import React, { useEffect } from 'react';

import { createTheme , MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';

import Loadable from 'react-loadable';

const AppRoute = Loadable({
    loader: () => import('./component/route'),
    loading: () => (<label>&nbsp;</label>)
});

const Alert = Loadable({
    loader: () => import('./component/alert/container'),
    loading: () => (<label>&nbsp;</label>)
});

// All the following keys are optional.
// We try our best to provide a great default value.
let theme = createTheme({
    button : {
		add    : {
			main : '#4CAF50'
		},
		edit   : {
			main : '#008CBA'
		},
		delete : {
			main : '#F2485A'
		}
	},
    typography : {
        fontFamily : [
            'Corben',
            'cursive',
            'Sora',
            'sans-serif'
        ].join(','),
        h5 : {
            fontFamily : [
                'Corben',
                'cursive'
            ].join(',')
        },
        subtitle1 : {
            fontFamily : [
                'Sora',
                'sans-serif'
            ].join(',')
        },
        body1 : {
            fontFamily : [
                'Sora',
                'sans-serif'
            ].join(',')
        },
        body2 : {
            fontFamily : [
                'Sora',
                'sans-serif'
            ].join(',')
        },
        caption : {
            fontFamily : [
                'Sora',
                'sans-serif'
            ].join(',')
        }
    },

    overrides : {
        MuiFormLabel : {
            fontFamily : [
                'Sora',
                'sans-serif'
            ].join(',')
        },

        MuiTooltip : {
            tooltip : {
                fontFamily : [
                    'Sora',
                    'sans-serif'
                ].join(',')
            }
        }
    }
});

theme = responsiveFontSizes(theme);


const App = () => {

    useEffect(()=>{
        if( process.env.NODE_ENV !== 'development' ) {
            window.addEventListener('contextmenu', handleContextMenu, false );
        }
    },[]);

    const handleContextMenu = event => event.preventDefault();

    const view = <AppRoute />;

    return (
        <MuiThemeProvider theme={theme}>
            <Alert/>
            {view}
        </MuiThemeProvider>
    );
}

export default App;