import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './rootSaga';

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();

let middleWare = [ sagaMiddleware ];

if( process.env.hasOwnProperty('NODE_ENV') && process.env.NODE_ENV === 'development' ) {
    middleWare = [ ...middleWare, loggerMiddleware ];
}

function configureStore(preloadedState) {
    return createStore(
        rootReducer,
        preloadedState,
        applyMiddleware( ...middleWare )
    )
}

const store = configureStore();

sagaMiddleware.run( rootSaga );

export default store;