import {
    FETCH_PENDING_TESTIMONIALS, FETCH_PENDING_TESTIMONIALS_DONE,
    SET_PENDING_TESTIMONIALS,
    
    FETCH_APPROVED_TESTIMONIALS,
    FETCH_APPROVED_TESTIMONIALS_DONE, SET_APPROVED_TESTIMONIALS,
    
    FETCH_DECLINED_TESTIMONIALS, FETCH_DECLINED_TESTIMONIALS_DONE,
    SET_DECLINED_TESTIMONIALS,
    
    APPROVE_TESTIMONIAL,
    DECLINE_TESTIMONIAL,
    
    SET_APP_DEC_DONE,

    ADD_VID_TESTIMONIAL, ADD_VID_TESTIMONIAL_DONE,
    
    DEL_VID_TESTIMONIAL, DEL_VID_TESTIMONIAL_DONE
} from './actions';

import { INIT_STATUS, FETCHING, FETCH_DONE } from './../../../config/constants';

const initState = {
    pendingTestimonials : {},
    approvedTestimonials: {},
    declinedTestimonials: {},
    
    fetchStatus         : INIT_STATUS,
    appDecStatus        : INIT_STATUS,
    addVidStatus        : INIT_STATUS,
    addVidSuccess        : null
};

const testimonialsAdmin = ( state = initState, { type, payload } ) => {
    let retVal = state;

    switch ( type ) {
        case FETCH_PENDING_TESTIMONIALS :
        case FETCH_APPROVED_TESTIMONIALS :
        case FETCH_DECLINED_TESTIMONIALS : {
            retVal = {
                ...retVal,
                fetchStatus : FETCHING
            };

            break;
        }

        case FETCH_PENDING_TESTIMONIALS_DONE :
        case FETCH_APPROVED_TESTIMONIALS_DONE :
        case FETCH_DECLINED_TESTIMONIALS_DONE : {
            retVal = {
                ...retVal,
                fetchStatus : FETCH_DONE
            };

            break;
        }

        case SET_PENDING_TESTIMONIALS : {
            const { testimonials } = payload;

            retVal = {
                ...retVal,
                pendingTestimonials : testimonials
            };
            break;
        }

        case SET_APPROVED_TESTIMONIALS : {
            const { testimonials } = payload;

            retVal = {
                ...retVal,
                approvedTestimonials : testimonials
            };
            break;
        }

        case SET_DECLINED_TESTIMONIALS : {
            const { testimonials } = payload;

            retVal = {
                ...retVal,
                declinedTestimonials : testimonials
            };
            break;
        }

        case APPROVE_TESTIMONIAL :
        case DECLINE_TESTIMONIAL : {
            retVal = {
                ...retVal,
                appDecStatus : FETCHING
            };

            break;
        }

        case SET_APP_DEC_DONE : {
            retVal = {
                ...retVal,
                appDecStatus : FETCH_DONE
            };

            break;
        }

        case ADD_VID_TESTIMONIAL :
        case DEL_VID_TESTIMONIAL:  {
            retVal = {
                ...retVal,
                addVidStatus : FETCHING,
                addVidSuccess : null
            };
            break;
        }

        case ADD_VID_TESTIMONIAL_DONE :
        case DEL_VID_TESTIMONIAL_DONE: {
            const { success } = payload;

            retVal = {
                ...retVal,
                addVidStatus : FETCH_DONE,
                addVidSuccess : success
            };
            break;
        }

        default : {
            retVal = {
                ...retVal
            };

            break;
        }
    }

    return retVal;
}

export default testimonialsAdmin;