import {
    SET_INSESSION, SET_TOKEN, SET_PERMISSIONS,
    DO_LOGIN, DO_LOGIN_DONE
} from './actions';

import { INIT_STATUS, FETCHING, FETCH_DONE } from './../../config/constants';

const initState = {
    token       : '',
    inSession   : false,
    permissions : [],
    loginStatus : INIT_STATUS
};

const session = ( state = initState, { type, payload } ) => {
    let retVal = state;

    switch ( type ) {
        case SET_INSESSION : {
            const { inSession } = payload;

            retVal = {
                ...retVal,
                inSession
            }
            break;
        }

        case SET_TOKEN : {
            const { token } = payload;

            retVal = {
                ...retVal,
                token,
                inSession : true
            }

            break;
        }

        case SET_PERMISSIONS : {
            const { permissions } = payload;

            retVal = {
                ...retVal,
                permissions
            }

            break;
        }

        case DO_LOGIN : {
            retVal = {
                ...retVal,
                loginStatus : FETCHING
            }

            break;
        }

        case DO_LOGIN_DONE : {
            retVal = {
                ...retVal,
                loginStatus : FETCH_DONE
            }

            break;
        }
    }

    return retVal;
}

export default session;