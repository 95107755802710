import HTTP from '../util/HTTP';

class Contact {
    sendInquiry( email, comment, contact ) {
        const url = `/api.php`;

        const params = {
            email, comment, contact,
            m : 'sendInquiry'
        }

        return HTTP.post( url, params ,'sendInquiry');
    }
}

export default new Contact();