import {
    all, takeLatest, call, put
} from 'redux-saga/effects';
import CareersActions, {
    SEND_APPLICATION
} from './actions';
import Careers from '../../api/Careers';
import AlertActions from '../alert/action';

function* sendApplication({ payload }) {
    try {
        const { params } = payload;

        const { success = true, message = ''} = yield call ([ Careers, Careers.sendApplication ], params );

        yield put( CareersActions.sendApplicationDone() );

        if( !success ) {
            yield put( AlertActions.addError( message ) );
            yield put( CareersActions.clearApplicationFields( false ) );
        } else {
            yield put( AlertActions.addSuccess( message ) );
            yield put( CareersActions.clearApplicationFields( true ) );
        }

    } catch ( error ) {
        yield put( CareersActions.sendApplicationDone() );
        yield put( AlertActions.addError( error ) );
    }
}

function* watchCareers() {
    yield takeLatest( SEND_APPLICATION, sendApplication );
}

export default function* careersRoot() {
    yield all([
        call( watchCareers )
    ]);
}