import {
    SET_UPLOAD_PROGRESS
} from './actions';

const initState = {
    upload       : 0
};

const progress = ( state = initState, { type, payload } ) => {
    let retVal = state;

    switch ( type ) {
        case SET_UPLOAD_PROGRESS : {
            const { progress } = payload;

            retVal = {
                ...retVal,
                upload : progress
            }
            break;
        }
    }

    return retVal;
}

export default progress;