export const ADD_SUCCESS = 'addSuccess',
            RM_SUCCESS   = 'removeSuccess',
            ADD_ERROR    = 'addError',
            RM_ERROR     = 'removeError',
            ADD_INFO     = 'addInfo',
            RM_INFO      = 'removeInfo',
            ADD_WARN     = 'addWarning',
            RM_WARN      = 'removeWarning';

class AlertActions {
    addSuccess = message => ({
        type    : ADD_SUCCESS,
        payload : {
            message
        }
    })

    removeSuccess = key => ({
        type    : RM_SUCCESS,
        payload : {
            key
        }
    })

    addError = message => ({
        type    : ADD_ERROR,
        payload : {
            message
        }
    })

    removeError = key => ({
        type    : RM_ERROR,
        payload : {
            key
        }
    })

    addInfo = message => ({
        type    : ADD_INFO,
        payload : {
            message
        }
    })

    removeInfo = key => ({
        type    : RM_INFO,
        payload : {
            key
        }
    })

    addWarning = message => ({
        type    : ADD_WARN,
        payload : {
            message
        }
    })

    removeWarning = key => ({
        type    : RM_WARN,
        payload : {
            key
        }
    })
}

export default new AlertActions();