import { createSelector } from 'reselect';

const session = state => state.session;

export const getToken = createSelector(
    session,
    session => session.token
);

export const getLoginStatus = createSelector(
    session,
    session => session.loginStatus
);

export const isLoggedIn = createSelector(
    session,
    session => {

        let inSession = session.inSession,
            token = localStorage.getItem('tkn');

        if( token ) {
            inSession = true;
        }

        return inSession
    }
);

const getPermissions = createSelector(
    session,
    session => session.permissions
);

export const hasPermission = code => createSelector(
    getPermissions,
    permissions => {
        const found = permissions.find( ({ ComponentCode } ) => ComponentCode === code );

        if( found ) {
            return true;
        }

        return false;
    }
);