export const INIT_STATUS = 0,
            FETCHING     = 1,
            FETCH_DONE   = 2,
            has          = Object.prototype.hasOwnProperty,
            ADD          = 1,
            EDIT         = 2;

export const ERR_CODES = {
    InvalidAccount : 'Username/password is not valid',
    InvalidSource  : 'Source device is invalid'
}