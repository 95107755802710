import {
    all, takeLatest, call, put, select
} from 'redux-saga/effects';
import TestimonalActions, {
    FETCH_PENDING_TESTIMONIALS,
    FETCH_APPROVED_TESTIMONIALS,
    FETCH_DECLINED_TESTIMONIALS,
    APPROVE_TESTIMONIAL,
    DECLINE_TESTIMONIAL, ADD_VID_TESTIMONIAL,
    DEL_VID_TESTIMONIAL
} from './actions';
import Testimonial from '../../../api/Testimonial';
import AlertActions from './../../alert/action';
import SessionActions from './../../session/actions';
import TestimonialClientActions from './../../testimonials/actions';
import Progressctions from './../../progress/actions';

function* logout() {
    yield put( SessionActions.doLogout() );
}

function* fetchPendingTestimonial() {
    try {
        const { success = false, message = '', data = []} = yield call([ Testimonial, Testimonial.getPendingTestimonials ]);


        yield put ( TestimonalActions.fetchPendingTestimonialsDone());

        console.log('>>>success', success );

        if( !success ) {
            if( message === 'Unauthorized') {
                yield call( logout );
            }
            yield put( AlertActions.addError( message ) );
        } else {
            const testimonials = data.reduce(( accumulator, record ) => ({
                ...accumulator,
                [ record.id ] :{
                    ...record
                }
            }),{});

            yield put( TestimonalActions.setPendingTestimonials( testimonials ));
        }
    } catch ( error ) {
        yield put ( TestimonalActions.fetchPendingTestimonialsDone());
    }
}

function* fetchApprovedTestimonial() {
    try {
        const { success = false, message = '', data = []} = yield call([ Testimonial, Testimonial.getTestimonials ]);


        yield put ( TestimonalActions.fetchApprovedTestimonialsDone());

        if( !success ) {
            if( message === 'Unauthorized') {
                yield call( logout );
            }
            yield put( AlertActions.addError( message ) );
        } else {
            const testimonials = data.reduce(( accumulator, record ) => ({
                ...accumulator,
                [ record.id ] :{
                    ...record
                }
            }),{});

            yield put( TestimonalActions.setApprovedTestimonials( testimonials ));
        }
    } catch ( error ) {
        yield put ( TestimonalActions.fetchApprovedTestimonialsDone());
        console.log( error );
    }
}

function* fetchDeclinedTestimonial() {
    try {
        const { success = false, message = '', data = []} = yield call([ Testimonial, Testimonial.getDeclinedTestimonials ]);


        yield put ( TestimonalActions.fetchDeclinedTestimonialsDone());

        if( !success ) {
            if( message === 'Unauthorized') {
                yield call( logout );
            }
            yield put( AlertActions.addError( message ) );
        } else {
            const testimonials = data.reduce(( accumulator, record ) => ({
                ...accumulator,
                [ record.id ] :{
                    ...record
                }
            }),{});

            yield put( TestimonalActions.setDeclinedTestimonials( testimonials ));
        }
    } catch ( error ) {
        yield put ( TestimonalActions.fetchDeclinedTestimonialsDone());
        console.log( error );
    }
}

function* approveTestimonial({ payload }) {
    try {
        const { id } = payload;
        const { success = false, message = ''} = yield call([ Testimonial, Testimonial.approveTestimonial ], id );

        yield put( TestimonalActions.setAppDecDone() );

        if( !success ) {
            if( message === 'Unauthorized') {
                yield call( logout );
            }
            yield put( AlertActions.addError( message ) );
        } else {
            yield put( AlertActions.addSuccess( message ) );
            yield call( fetchPendingTestimonial );
        }

    } catch ( error ) {
        yield put( TestimonalActions.setAppDecDone() );
        console.log( error );
    }
}

function* declineTestimonial({ payload }) {
    try {
        const { id } = payload;
        const { success = false, message = ''} = yield call([ Testimonial, Testimonial.declineTestimonial ], id );

        yield put( TestimonalActions.setAppDecDone() );

        if( !success ) {
            if( message === 'Unauthorized') {
                yield call( logout );
            }
            yield put( AlertActions.addError( message ) );
        } else {
            yield put( AlertActions.addSuccess( message ) );
            yield call( fetchPendingTestimonial );
        }

    } catch ( error ) {
        yield put( TestimonalActions.setAppDecDone() );
        console.log( error );
    }
}

function* addVidTestimonial( { payload } ) {
    try {
        const { params } = payload;

        const { success = false, message = ''} = yield call ([ Testimonial, Testimonial.addVideoTestimonial ], params );

        yield put( TestimonalActions.addVidTestimonialDone( success ) );

        if( !success ) {
            yield put( AlertActions.addError( message ) );
        }

        yield put( AlertActions.addSuccess( message ) );
        yield put ( Progressctions.setUploadProgress( 0 ) );
        yield put (TestimonialClientActions.fetchVidTestimonials() );

    } catch ( error ) {
        yield put( TestimonalActions.addVidTestimonialDone( false) );
        console.log( error );
    }
}

function* delVidTestimonial( { payload } ) {
    try {
        const { id } = payload;

        const { success = false, message = ''} = yield call ([ Testimonial, Testimonial.deleteVidTestimonial ], id );

        yield put( TestimonalActions.addVidTestimonialDone( success ) );

        if( !success ) {
            yield put( AlertActions.addError( message ) );
        }

        yield put( AlertActions.addSuccess( message ) );
        yield put (TestimonialClientActions.fetchVidTestimonials() );

    } catch ( error ) {
        yield put( TestimonalActions.addVidTestimonialDone( false) );
        console.log( error );
    }
}

function* watchTestimonial() {
    yield takeLatest( FETCH_PENDING_TESTIMONIALS, fetchPendingTestimonial );
    yield takeLatest( FETCH_APPROVED_TESTIMONIALS, fetchApprovedTestimonial );
    yield takeLatest( FETCH_DECLINED_TESTIMONIALS, fetchDeclinedTestimonial );
    yield takeLatest( APPROVE_TESTIMONIAL, approveTestimonial );
    yield takeLatest( DECLINE_TESTIMONIAL, declineTestimonial );
    yield takeLatest( ADD_VID_TESTIMONIAL, addVidTestimonial );
    yield takeLatest( DEL_VID_TESTIMONIAL, delVidTestimonial );
}

export default function* testimonailAdminRoot() {
    yield all([
        call( watchTestimonial )
    ]);
}