import axios from 'axios';
import qs from 'qs';
import store from './../configureStore';
import Progressctions from './../component/progress/actions';

const getToken = () => {}

const CancelToken = axios.CancelToken;

class HTTP {

    cancel = null;
    
    cancelAllRequests () {
        if( this.cancel ) {
            this.cancel();
        }
    }

    async _getHeaders() {
        const state = store.getState(),
            token   = getToken( state ) || localStorage.getItem( 'tkn' );

        let headers = {
            'Accept'          : '*/*',
            'Content-Type'    : 'application/x-www-form-urlencoded; charset = UTF-8',
            'X-Requested-With': 'XMLHttpRequest',
            'credentials'       : 'same-origin'
        };

        this.token = token;

        if( this.token ) {
            headers.Authorization = 'Bearer ' + this.token;
        }

        return headers;
    }

    async _getUploadHeaders() {
        const state = store.getState(),
            token   = getToken( state ) || localStorage.getItem( 'tkn' );
        
        let headers = {
            'X-Requested-With': 'XMLHttpRequest'
        };

        this.token = token;

        if( this.token ) {
            headers.Authorization = 'Bearer ' + this.token;
        }

        return headers;
    }

    async post( url, data = null, key = '' ) {
        const headers = await this._getHeaders();

        const options = {
            url,
            headers,
            method     : 'POST',
            data       : qs.stringify(data),
            cancelToken: new CancelToken( cancl => {
                // An executor function receives a cancel function as a parameter
                this.cancel = cancl;
            })
        };
        
        return axios(options)
                .then( response => {

                    if( response.status === 200 ) {
                        const { success, message = '' } = response.data;
                        
                        if( response && response.data ) {
                            if( !success ) {
                                if( message.toLowerCase().indexOf('sessionexpired') > -1 || message.toLowerCase().indexOf('invalidsession') > -1 ) {
                                    this.showLoginScreen();
                                }
                            }
                            return Promise.resolve( response.data );
                        } else {
                            // localStorage.removeItem('User');
                            // window.location.reload( true );
                        }
                    } else {
                        // store.dispatch(addAppError( key || url, 'Error fetching data. Status : ' + response.status ));
                        throw new Error('Error fetching data. Status : ' + response.status);
                    }
                })
                .catch( error => {
                    // Snackbar.show({
                    //     title           : error.message,
                    //     backgroundColor : '#ffd2d2',
                    //     color           : '#D8000C'
                    // });
                    // store.dispatch( addAppError( key || url, error.message ));
                    throw error.message;
                });
    }

    onUploadProgress ( progressEvent ) {
        const progress = progressEvent.loaded / progressEvent.total;

        store.dispatch( Progressctions.setUploadProgress( progress ) );
    }

    async postUpload( url, /**Form Data */ data, key = '' ) {
        const headers = await this._getUploadHeaders();

        const options = {
            url,
            headers,
            method     : 'POST',
            data       : data,
            onUploadProgress : this.onUploadProgress,
            cancelToken: new CancelToken( cancl => {
                // An executor function receives a cancel function as a parameter
                this.cancel = cancl;
            })
        };
        
        return axios(options)
                .then( response => {

                    if( response.status === 200 ) {
                        const { success, message = '' } = response.data;

                        if( response && response.data ) {
                            if( !success ) {
                                if( message.toLowerCase().indexOf('sessionexpired') > -1 || message.toLowerCase().indexOf('invalidsession') > -1 ) {
                                    this.showLoginScreen();
                                }
                            }
                            return Promise.resolve( response.data );
                        } else {
                        }
                    } else {
                        // store.dispatch(addAppError( key || url, 'Error upload. Status : ' + response.status ));
                        throw new Error('Error upload. Status : ' + response.status);
                    }
                })
                .catch( error => {
                    // Snackbar.show({
                    //     title           : error.message,
                    //     backgroundColor : '#ffd2d2',
                    //     color           : '#D8000C'
                    // });
                    // store.dispatch(addAppError( key || url, error.message ));
                    throw error.message;
                });
    }

    async putUpload( url, /**Form Data */ data, key = '' ) {
        const headers = await this._getUploadHeaders(); 

        const options = {
            url,
            headers,
            method     : 'PUT',
            data       : data,
            onUploadProgress : this.onUploadProgress,
            cancelToken: new CancelToken( cancl => {
                // An executor function receives a cancel function as a parameter
                this.cancel = cancl;
            })
        };
        
        return axios(options)
                .then( response => {

                    if( response.status === 200 ) {
                        const { success, message = '' } = response.data;

                        if( response && response.data ) {
                            if( !success ) {
                                if( message.toLowerCase().indexOf('sessionexpired') > -1 || message.toLowerCase().indexOf('invalidsession') > -1 ) {
                                    this.showLoginScreen();
                                }
                            }
                            return Promise.resolve( response.data );
                        } else {
                        }
                    } else {
                        // store.dispatch(addAppError( key || url, 'Error upload. Status : ' + response.status ));
                        throw new Error('Error upload. Status : ' + response.status);
                    }
                })
                .catch( error => {
                    // Snackbar.show({
                    //     title           : error.message,
                    //     backgroundColor : '#ffd2d2',
                    //     color           : '#D8000C'
                    // });
                    // store.dispatch(addAppError( key || url, error.message ));
                    throw error.message;
                });
    }

    async get( url, data, key = '' ) {
        const headers = await this._getHeaders();

        const options = {
            url,
            headers,
            method     : 'GET',
            params     : data,
            cancelToken: new CancelToken( cancl => {
                // An executor function receives a cancel function as a parameter
                this.cancel = cancl;
            })
        };
        
        return axios(options)
                .then( response => {
                    if( response.status === 200 ) {

                        if( response.hasOwnProperty( 'data' ) ) {
                            const { success, message = '' } = response.data;

                            if( !success ) {
                                
                                if( message.toLowerCase().indexOf('sessionexpired') > -1 || message.toLowerCase().indexOf('invalidsession') > -1 ) {
                                    this.showLoginScreen();
                                }
                            }
                        }
                        
                        return Promise.resolve( response.data );
                    } else {
                        // store.dispatch(addAppError( key || url, 'Error fetching data. Status : ' + response.status ));
                        throw new Error('Error fetching data. Status : ' + response.status);
                    }
                })
                .catch( error => {
                    // Snackbar.show({
                    //     title           : error.message,
                    //     backgroundColor : '#ffd2d2',
                    //     color           : '#D8000C'
                    // });
                    // store.dispatch(addAppError( key || url, error.message ));
                    throw error.message;
                });
    }

    async put( url, data = null, key = '' ) {
        const headers = await this._getHeaders();

        const options = {
            url,
            headers,
            method     : 'PUT',
            data       : qs.stringify(data),
            cancelToken: new CancelToken( cancl => {
                // An executor function receives a cancel function as a parameter
                this.cancel = cancl;
            })
        };
        
        return axios(options)
                .then( response => {
                    if( response.status === 200 ) {
                        const { success, message = '' } = response.data;
                        
                        if( response && response.data ) {
                            if( !success ) {
                                if( message.toLowerCase().indexOf('sessionexpired') > -1 || message.toLowerCase().indexOf('invalidsession') > -1 ) {
                                    this.showLoginScreen();
                                }
                            }
                            return Promise.resolve( response.data );
                        } else {
                            // localStorage.removeItem('User');
                            // window.location.reload( true );
                        }
                    } else {
                        // store.dispatch(addAppError( key || url, 'Error fetching data. Status : ' + response.status ));
                        throw new Error('Error fetching data. Status : ' + response.status);
                    }
                })
                .catch( error => {
                    // Snackbar.show({
                    //     title           : error.message,
                    //     backgroundColor : '#ffd2d2',
                    //     color           : '#D8000C'
                    // });
                    // store.dispatch( addAppError( key || url, error.message ));
                    throw error.message;
                });
    }

    async delete( url, data = null, key = '' ) {
        const headers = await this._getHeaders();

        const options = {
            url,
            headers,
            method     : 'DELETE',
            data       : qs.stringify(data),
            cancelToken: new CancelToken( cancl => {
                // An executor function receives a cancel function as a parameter
                this.cancel = cancl;
            })
        };
        
        return axios(options)
                .then( response => {

                    if( response.status === 200 ) {
                        const { success, message = '' } = response.data;
                        
                        if( response && response.data ) {
                            if( !success ) {
                                if( message.toLowerCase().indexOf('sessionexpired') > -1 || message.toLowerCase().indexOf('invalidsession') > -1 ) {
                                    this.showLoginScreen();
                                }
                            }
                            return Promise.resolve( response.data );
                        } else {
                            // localStorage.removeItem('User');
                            // window.location.reload( true );
                        }
                    } else {
                        // store.dispatch(addAppError( key || url, 'Error fetching data. Status : ' + response.status ));
                        throw new Error('Error fetching data. Status : ' + response.status);
                    }
                })
                .catch( error => {
                    // Snackbar.show({
                    //     title           : error.message,
                    //     backgroundColor : '#ffd2d2',
                    //     color           : '#D8000C'
                    // });
                    // store.dispatch( addAppError( key || url, error.message ));
                    throw error.message;
                });
    }

    showLoginScreen = () => {
        const localTOken = localStorage.getItem('tkn');

        if( localTOken ) {
            localStorage.removeItem('tkn');
            window.location.reload( true );
        }
    }
}

export default new HTTP();