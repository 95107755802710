import {
    all, takeLatest, call, put
} from 'redux-saga/effects';
import TestimonialActions, {
    FETCH_TESTIMONIALS, ADD_TESTIMONIAL,
    FETCH_VID_TESTIMONIALS
} from './actions';
import Testimonial from './../../api/Testimonial';
import AlertActions from './../alert/action';

function* getTestimonials() {
    try {
        const result = yield call ([ Testimonial, Testimonial.getTestimonials ] );

        yield put( TestimonialActions.fetchTestimonialsDone() );

        if( !result.success ) {
            return;
        }

        const testimonials = result.data.reduce(( accumulator, testimonial )=>({
            ...accumulator,
            [ testimonial.id ] : {
                ...testimonial
            }
        }), {});

        yield put( TestimonialActions.setTestimonials( testimonials ) )

    } catch ( error ) {
        yield put( TestimonialActions.fetchTestimonialsDone() );
        console.log( error );
    }
}

function* getVidTestimonials() {
    try {
        const result = yield call ([ Testimonial, Testimonial.getVideoTestimonials ] );

        yield put( TestimonialActions.fetchVidTestimonialsDone() );

        if( !result.success ) {
            return;
        }

        const videos = result.data.reduce(( accumulator, video )=>({
            ...accumulator,
            [ video.id ] : {
                ...video
            }
        }), {});

        yield put( TestimonialActions.setVidTestimonials( videos ) )

    } catch ( error ) {
        yield put( TestimonialActions.fetchVidTestimonialsDone() );
        console.log( error );
    }
}

function* addTestimonial( { payload } ) {
    try {
        const { params } = payload;

        const { success = false, message = ''} = yield call ([ Testimonial, Testimonial.addTestimonial ], params );

        yield put( TestimonialActions.addTestimonialDone( success ) );

        if( !success ) {
            yield put( AlertActions.addError( message ) );
        }

    } catch ( error ) {
        yield put( TestimonialActions.fetchTestimonialsDone() );
        console.log( error );
    }
}

function* watchTestimonial() {
    yield takeLatest( FETCH_TESTIMONIALS, getTestimonials );
    yield takeLatest( ADD_TESTIMONIAL, addTestimonial );
    yield takeLatest( FETCH_VID_TESTIMONIALS, getVidTestimonials );
}

export default function* testimonialRoot() {
    yield all([
        call( watchTestimonial )
    ]);
}