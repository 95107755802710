import HTTP from '../util/HTTP';

class Careers {
    sendApplication( params ) {
        const url = `/career.php`;

        return HTTP.post( url, {
            ...params,
            m : 'sendApplication'
        } ,'sendApplication');
    }

    getPendingApplications( params ) {
        const url = `/career.php`;

        return HTTP.post( url, {
            m : 'getPendingApplications',
            ...params
        } ,'getPendingApplications');
    }

    getForIterviewApplications( params ) {
        const url = `/career.php`;

        return HTTP.post( url, {
            m : 'getForIterviewApplications',
            ...params
        } ,'getForIterviewApplications');
    }

    getDoneIterviewApplications( params ) {
        const url = `/career.php`;

        return HTTP.post( url, {
            m : 'getDoneIterviewApplications',
            ...params
        } ,'getDoneIterviewApplications');
    }

    getFailedApplications( params ) {
        const url = `/career.php`;

        return HTTP.post( url, {
            m : 'getFailedApplications',
            ...params
        } ,'getFailedApplications');
    }

    getDeclinedApplications( params ) {
        const url = `/career.php`;

        return HTTP.post( url, {
            m : 'getDeclinedApplications',
            ...params
        } ,'getDeclinedApplications');
    }

    getHiredApplications( params ) {
        const url = `/career.php`;

        return HTTP.post( url, {
            m : 'getHiredApplications',
            ...params
        } ,'getHiredApplications');
    }

    updateApplicationStatus( params ) {
        const url = `/career.php`;

        return HTTP.post( url, {
            ...params,
            m : 'updateApplicationStatus'
        } ,'updateApplicationStatus');
    }
}

export default new Careers();