import {
    spawn, call, all
} from 'redux-saga/effects';

import testimonialRoot from './component/testimonials/saga';
import contactUsRoot from './component/contactUs/saga';
import sessionRoot from './component/session/saga';
import testimonailAdminRoot from './component/admin/testimonials/saga';
import careersRoot from './component/careers/saga';
import careersAdminRoot from './component/admin/applications/saga';
import userRoot from './component/admin/user/saga';

export default function* rootSaga() {
    const sagas = [
        testimonialRoot, contactUsRoot,
        sessionRoot, testimonailAdminRoot,
        careersRoot, careersAdminRoot,
        userRoot
    ];
    
    yield all(sagas.map(saga =>
        spawn(function* () {
            while (true) {
                try {
                    yield call(saga)
                    break
                } catch (e) {
                
                }
            }
        })
    ));
}