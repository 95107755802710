export const SEND_APPLICATION    = 'sendApplication',
        SEND_APPLICATION_DONE    = 'sendApplicationDone',
        CLEAR_APPLICATION_FIELDS = 'clearApplicationFields';

class CareersActions {
    sendApplication = params => ({
        type    : SEND_APPLICATION,
        payload : {
            params
        }
    })

    sendApplicationDone = () => ({
        type : SEND_APPLICATION_DONE
    })

    clearApplicationFields = clear => ({
        type   : CLEAR_APPLICATION_FIELDS,
        payload: {
            clear
        }
    })
}

export default new CareersActions();