export const SET_ABOUTUS_SUBROUTE = 'setAboutUsSubRoute';

class AboutUsActions {
    setAboutUsSubRoute = route => ({
        type    : SET_ABOUTUS_SUBROUTE,
        payload : {
            route
        }
    })
}

export default new AboutUsActions();