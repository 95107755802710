export const FETCH_PENDING_TESTIMONIALS      = 'fetchPendingTestimonials',
            FETCH_PENDING_TESTIMONIALS_DONE  = 'fetchPendingTestimonialsDone',
            SET_PENDING_TESTIMONIALS         = 'setPendingTestimonials',

            FETCH_APPROVED_TESTIMONIALS      = 'fetchApprovedTestimonials',
            FETCH_APPROVED_TESTIMONIALS_DONE = 'fetchApprovedTestimonialsDone',
            SET_APPROVED_TESTIMONIALS        = 'setApprovedTestimonials',
            
            FETCH_DECLINED_TESTIMONIALS      = 'fetchDeclinedTestimonials',
            FETCH_DECLINED_TESTIMONIALS_DONE = 'fetchDeclinedTestimonialsDone',
            SET_DECLINED_TESTIMONIALS        = 'setDeclinedTestimonials',
            
            APPROVE_TESTIMONIAL              = 'approveTestimonial',
            DECLINE_TESTIMONIAL              = 'declineTestimonial',
            SET_APP_DEC_DONE                 = 'setAppDecDone',
            
            ADD_VID_TESTIMONIAL             = 'addVidTestimonial',
            ADD_VID_TESTIMONIAL_DONE        = 'addVidTestimonialDone',
            
            DEL_VID_TESTIMONIAL             = 'delVidTestimonial',
            DEL_VID_TESTIMONIAL_DONE        = 'delVidTestimonialDone';

class TestimonalActions {
    fetchPendingTestimonials = () => ({
        type    : FETCH_PENDING_TESTIMONIALS
    })

    fetchPendingTestimonialsDone = () => ({
        type    : FETCH_PENDING_TESTIMONIALS_DONE
    })

    setPendingTestimonials = testimonials => ({
        type    : SET_PENDING_TESTIMONIALS,
        payload : {
            testimonials
        }
    })

    fetchApprovedTestimonials = () => ({
        type    : FETCH_APPROVED_TESTIMONIALS
    })

    fetchApprovedTestimonialsDone = () => ({
        type    : FETCH_APPROVED_TESTIMONIALS_DONE
    })

    setApprovedTestimonials = testimonials => ({
        type    : SET_APPROVED_TESTIMONIALS,
        payload : {
            testimonials
        }
    })

    fetchDeclinedTestimonials = () => ({
        type    : FETCH_DECLINED_TESTIMONIALS
    })

    fetchDeclinedTestimonialsDone = () => ({
        type    : FETCH_DECLINED_TESTIMONIALS_DONE
    })

    setDeclinedTestimonials = testimonials => ({
        type    : SET_DECLINED_TESTIMONIALS,
        payload : {
            testimonials
        }
    })

    approveTestimonial = id => ({
        type   : APPROVE_TESTIMONIAL,
        payload: {
            id
        }
    })

    declineTestimonial = id => ({
        type   : DECLINE_TESTIMONIAL,
        payload: {
            id
        }
    })

    setAppDecDone = () => ({
        type : SET_APP_DEC_DONE
    })

    addVidTestimonial = params => ({
        type   : ADD_VID_TESTIMONIAL,
        payload: {
            params
        }
    })

    addVidTestimonialDone = success => ({
        type : ADD_VID_TESTIMONIAL_DONE,
        payload : {
            success
        }
    })

    delVidTestimonial = id => ({
        type   : DEL_VID_TESTIMONIAL,
        payload: {
            id
        }
    })

    dekVidTestimonialDone = success => ({
        type : DEL_VID_TESTIMONIAL_DONE,
        payload : {
            success
        }
    })
    
}

export default new TestimonalActions();