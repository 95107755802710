import { 
    SEND_APPLICATION, SEND_APPLICATION_DONE,
    CLEAR_APPLICATION_FIELDS
} from './actions';

import {
    INIT_STATUS, FETCHING, FETCH_DONE
} from './../../config/constants';

const initState = {
    sendhStatus  : INIT_STATUS,
    clearFields  : false
};

const careers = ( state = initState, { type, payload } ) => {
    let retState = state;

    switch( type ) {
        case SEND_APPLICATION:{
            retState = {
                ...retState,
                sendhStatus : FETCHING
            }
            break;
        }

        case SEND_APPLICATION_DONE :{
            retState = {
                ...retState,
                sendhStatus : FETCH_DONE
            }
            break;
        }

        case CLEAR_APPLICATION_FIELDS : {
            const { clear } = payload;
            
            retState = {
                ...retState,
                clearFields : clear
            }
            break;
        }

        default : {
            retState = {
                ...retState
            }

            break;
        }
    }
    return retState;
};

export default careers;