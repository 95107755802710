import {
    all, takeLatest, call, put, select
} from 'redux-saga/effects';
import Session from './../../api/Session';
import Auth from './../../api/Auth';
import SessionActions, {
    CHECK_SESSION, DO_LOGIN, DO_LOGOUT
} from './actions';
import { isLoggedIn } from './selector';
import { getUserInfo  } from './../admin/user/selector';
import AlertActions from './../alert/action';
import UserActions from './../admin/user/actions';

function* checkSession() {
    try {
        const loggedIn = yield select( isLoggedIn );

        if( loggedIn ) {
            const tkn = localStorage.getItem('tkn');

            yield put( SessionActions.setInSession( true ) );
            yield put( SessionActions.setToken( tkn ) );

        } else {
            const { success = false, message = '', token = '', userInfo = {} } = yield call([ Session, Session.isLoggedIn ]);

            if( !success ) {
                yield put( SessionActions.setInSession( false ) );
            } else {
                yield call ([ localStorage, localStorage.setItem ], 'tkn', token );
                yield put( SessionActions.setInSession( true ) );
                yield put( SessionActions.setToken( token ) );
                yield put( UserActions.setUserInfo( userInfo ));
                yield call ([ localStorage, localStorage.setItem ], 'inf', JSON.stringify(userInfo) );
            }
        }

    } catch ( error ) {
        yield put( SessionActions.setInSession( false ) );
        console.log( error );
    }
}

function* doLogin({ payload }) {
    try {
        const { username, password, gCaptcha } = payload;
        const { success = false, message = '', userInfo = {}} = yield call([ Auth, Auth.login ], username, password, gCaptcha );

        if( !success ) {
            yield put( AlertActions.addError( message ) );
        } else {
            yield call ([ localStorage, localStorage.setItem ], 'tkn', message );
            yield put( SessionActions.setInSession( true ) );
            yield put( UserActions.setUserInfo( userInfo ));

            console.log('>>>>>userInfo', userInfo );

            yield call ([ localStorage, localStorage.setItem ], 'inf', JSON.stringify(userInfo) );
            yield put( SessionActions.setToken( message ) );
        }

        yield put( SessionActions.doLoginDone() );

    } catch ( error ) {
        yield put( SessionActions.setInSession( false ) );
        yield put( SessionActions.doLoginDone() );
        console.log( error );
    }
}

function* doLogout() {
    try {
        const { success = false, message = ''} = yield call([ Auth, Auth.logout ]);

        if( !success ) {
            yield put( AlertActions.addError( message ) );
        } else {
            yield call ([ localStorage, localStorage.removeItem ], 'tkn' );
            yield call ([ localStorage, localStorage.removeItem  ], 'inf');
            yield put ( SessionActions.setToken( '' ) );
            yield put( SessionActions.setInSession( false ) );
            window.location.reload( true );
        }
    } catch ( error ) {
        console.log( error );
    }
}

function* watchSession() {
    yield takeLatest( CHECK_SESSION, checkSession );
    yield takeLatest( DO_LOGIN, doLogin );
    yield takeLatest( DO_LOGOUT, doLogout );
}

export default function* sessionRoot() {
    yield all([
        call( watchSession )
    ]);
}