import HTTP from '../util/HTTP';

class Auth {
    login( username, password, gCaptcha = 'captcha' ) {
        const url = `/session.php`;

        const params = {
            username, password, gCaptcha,
            m : 'login'
        }

        return HTTP.post( url, params ,'login');
    }

    logout() {
        const url = `/session.php`;
        const params = {
            m : 'logout'
        }

        return HTTP.post( url, params ,'logout');
    }
}

export default new Auth();