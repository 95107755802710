import {
    all, takeLatest, call, put
} from 'redux-saga/effects';
import ContactUsActions, {
    SEND_INQUIRY
} from './actions';
import Contact from './../../api/Contact';
import AlertActions from './../alert/action';

function* sendInquiry({ payload }) {
    try {
        const { email, comment, contact } = payload;

        const { success = true, message = ''} = yield call ([ Contact, Contact.sendInquiry ], email, comment, contact );

        yield put( ContactUsActions.sendInquiryDone() );

        if( !success ) {
            yield put( AlertActions.addError( message ) );
            yield put( ContactUsActions.clearContactFields( false ) );
        } else {
            yield put( AlertActions.addSuccess( message ) );
            yield put( ContactUsActions.clearContactFields( true ) );
        }

    } catch ( error ) {
        yield put( ContactUsActions.sendInquiryDone() );
        yield put( AlertActions.addError( error ) );
    }
}

function* watchContactUs() {
    yield takeLatest( SEND_INQUIRY, sendInquiry )
}

export default function* contactUsRoot() {
    yield all([
        call( watchContactUs )
    ]);
}