import { combineReducers } from 'redux';

import testimonial from './component/testimonials/reducer';
import contactUs from './component/contactUs/reducer';
import alert from './component/alert/reducer';
import session from './component/session/reducer';
import testimonialsAdmin from './component/admin/testimonials/reducer';
import aboutUs from './component/aboutUs/reducer';
import careers from './component/careers/reducer';
import careersAdmin from './component/admin/applications/reducer';
import user from './component/admin/user/reducer';
import progress from './component/progress/reducer';

const appReducer = combineReducers({
    testimonial, contactUs, alert,
    session, testimonialsAdmin,
    aboutUs, careers, careersAdmin,
    user, progress
});

const reducer = (state, action) => {
    // if (action.type === SET_LOGOUT ) {
    //     state = { };
    // }

    return appReducer(state, action);
}

export default reducer;