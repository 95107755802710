export const CHECK_SESSION  = 'checkSession',
            SET_INSESSION   = 'setInSession',
            SET_TOKEN       = 'setToken',
            SET_PERMISSIONS = 'setPermissions',
            DO_LOGIN        = 'doLogin',
            DO_LOGIN_DONE   = 'doLoginDone',
            DO_LOGOUT       = 'doLogout',
            DO_LOGOUT_DONE  = 'doLogoutDone';

class SessionActions {
    checkSession = () => ({
        type    : CHECK_SESSION
    })

    setInSession = inSession => ({
        type    : SET_INSESSION,
        payload : {
            inSession
        }
    })

    setToken = token => ({
        type    : SET_TOKEN,
        payload : {
            token
        }
    })

    setPermissions = permissions => ({
        type    : SET_PERMISSIONS,
        payload : {
            permissions
        }
    })

    doLogin = ( username, password, gCaptcha ) => ({
        type : DO_LOGIN,
        payload : {
            username, password, gCaptcha
        }
    })

    doLoginDone = () => ({
        type : DO_LOGIN_DONE
    })

    doLogout = () => ({
        type : DO_LOGOUT
    })

    doLogoutDone = () => ({
        type : DO_LOGOUT_DONE
    })
}

export default new SessionActions();