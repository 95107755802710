export const FETCH_TESTIMONIALS     = 'fetchTestimonials',
        FETCH_TESTIMONIALS_DONE     = 'fetchTestimonialsDone',
        SET_TESTIMONIALS            = 'setTestimonials',

        ADD_TESTIMONIAL             = 'addTestimonial',
        ADD_TESTIMONIAL_DONE        = 'addTestimonialDone',
        
        FETCH_VID_TESTIMONIALS      = 'fetchVidTestimonials',
        FETCH_VID_TESTIMONIALS_DONE = 'fetchVidTestimonialsDone',
        SET_VID_TESTIMONIALS        = 'setVidTestimonials';

class TestimonialActions {
    fetchTestimonials = () => ({
        type : FETCH_TESTIMONIALS
    })

    fetchTestimonialsDone = () => ({
        type : FETCH_TESTIMONIALS_DONE
    })

    setTestimonials = testimonials => ({
        type   : SET_TESTIMONIALS,
        payload: {
            testimonials
        }
    })

    addTestimonial = params => ({
        type   : ADD_TESTIMONIAL,
        payload: {
            params
        }
    })

    addTestimonialDone = success => ({
        type : ADD_TESTIMONIAL_DONE,
        payload : {
            success
        }
    })

    fetchVidTestimonials = () => ({
        type : FETCH_VID_TESTIMONIALS
    })

    fetchVidTestimonialsDone = () => ({
        type : FETCH_VID_TESTIMONIALS_DONE
    })

    setVidTestimonials = videos => ({
        type   : SET_VID_TESTIMONIALS,
        payload: {
            videos
        }
    })
}

export default new TestimonialActions();