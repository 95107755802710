import HTTP from './../util/HTTP';

class Session {
    isLoggedIn() {
        const url = `/session.php`;

        return HTTP.post( url, {
            m : 'inSession'
        } ,'isLoggedIn');
    }
}

export default new Session();