import { 
    SEND_INQUIRY, SEND_INQUIRY_DONE,
    CLEAR_CONTACT_FIELDS
} from './actions';

import {
    INIT_STATUS, FETCHING, FETCH_DONE
} from './../../config/constants';

const initState = {
    sendhStatus : INIT_STATUS,
    clearFields : false
};

const contactUs = ( state = initState, { type, payload } ) => {
    let retState = state;

    switch( type ) {
        case SEND_INQUIRY:{
            retState = {
                ...retState,
                sendhStatus : FETCHING
            }
            break;
        }

        case SEND_INQUIRY_DONE : {
            retState = {
                ...retState,
                sendhStatus : FETCH_DONE
            }
            break;
        }

        case CLEAR_CONTACT_FIELDS : {
            const { clear } = payload;
            
            retState = {
                ...retState,
                clearFields : clear
            }
            break;
        }

        default : {
            retState = {
                ...retState
            }

            break;
        }
    }
    return retState;
};

export default contactUs;