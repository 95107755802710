import { 
    SET_ABOUTUS_SUBROUTE
} from './actions';

const initState = {
    route : ''
};

const aboutUs = ( state = initState, { type, payload } ) => {
    let retState = state;

    switch( type ) {
        case SET_ABOUTUS_SUBROUTE:{
            const { route } = payload;

            retState = {
                ...retState,
                route
            }
            break;
        }

        default : {
            retState = {
                ...retState
            }

            break;
        }
    }
    return retState;
};

export default aboutUs;