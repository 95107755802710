export const SET_USER_INFO = 'setUserInfo',
        FETCH_USERS        = 'fetchUsers',
        FETCH_USERS_DONE   = 'fetchUsersDone',
        SET_USERS          = 'setUsers',
        
        DELETE_USER        = 'deleteUser',
        DELETE_USER_DONE   = 'deleteUserDone',
        
        SAVE_USER          = 'saveUser',
        SAVE_USER_DONE     = 'saveUserDone',
        
        CHANGE_PASS        = 'changePass',
        CHANGE_PASS_DONE   = 'changePassDone';

class UserActions {
    fetchUsers = () => ({
        type : FETCH_USERS
    })

    fetchUsersDone = () => ({
        type : FETCH_USERS_DONE
    })

    setUsers = users => ({
        type : SET_USERS,
        payload : {
            users
        }
    })

    setUserInfo = userInfo => ({
        type : SET_USER_INFO,
        payload : {
            userInfo
        }
    })

    deleteUser = id => ({
        type   : DELETE_USER,
        payload: {
            id
        }
    })

    deleteUserDone = success => ({
        type   : DELETE_USER_DONE,
        payload : {
            success
        }
    })

    saveUser = params => ({
        type : SAVE_USER,
        payload : {
            params
        }
    })

    saveUserDone = success => ({
        type : SAVE_USER_DONE,
        payload : {
            success
        }
    })

    changePass = params => ({
        type : CHANGE_PASS,
        payload : {
            params
        }
    })

    changePassDone = success => ({
        type : CHANGE_PASS_DONE,
        payload : {
            success
        }
    })
}

export default new UserActions();