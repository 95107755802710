import { 
    ADD_SUCCESS, RM_SUCCESS, ADD_ERROR,
    RM_ERROR, ADD_INFO, RM_INFO, ADD_WARN,
    RM_WARN
} from './action';

const initState = {
    success : {},
    error   : {},
    warning : {},
    info    : {}
}

const alert = ( state = initState, { type, payload } ) => {
    let retState = state;

    switch( type ) {
        case ADD_SUCCESS : {
            const { message } = payload;

            retState = {
                ...retState,
                success : {
                    ...retState.success,
                    [ new Date().getTime() + Math.random() ] : message
                }
            }
            break;
        }

        case RM_SUCCESS : {
            const { key } = payload;

            const { success } = retState,
                { [ key ] : _, ...msg} = success;

            retState = {
                ...retState,
                success : msg
            }
            break;
        }

        case ADD_ERROR : {
            const { message } = payload;

            retState = {
                ...retState,
                error : {
                    ...retState.error,
                    [ new Date().getTime() + Math.random() ] : message
                }
            }
            break;
        }

        case RM_ERROR : {
            const { key } = payload;

            const { error } = retState,
                { [ key ] : _, ...msg} = error;

            retState = {
                ...retState,
                error : msg
            }
            break;
        }

        case ADD_INFO : {
            const { message } = payload;

            retState = {
                ...retState,
                info : {
                    ...retState.info,
                    [ new Date().getTime() + Math.random() ] : message
                }
            }
            break;
        }

        case RM_INFO : {
            const { key } = payload;

            const { info } = retState,
                { [ key ] : _, ...msg} = info;

            retState = {
                ...retState,
                info : msg
            }

            break;
        }

        case ADD_WARN : {
            const { message } = payload;

            retState = {
                ...retState,
                warning : {
                    ...retState.warning,
                    [ new Date().getTime() + Math.random() ] : message
                }
            }
            break;
        }

        case RM_WARN : {
            const { key } = payload;

            const { warning } = retState,
                { [ key ] : _, ...msg} = warning;

            retState = {
                ...retState,
                warning : msg
            }

            break;
        }

        default : {
            retState = {
                ...retState
            }

            break;
        }
    }
    return retState;
}

export default alert;