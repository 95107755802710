import HTTP from './../util/HTTP';

class Testimonial {
    getTestimonials( ) {
        const url = `/api.php`;

        const params = {
            m : 'getTestimonials'
        }

        return HTTP.post( url, params ,'testimonials');
    }

    getVideoTestimonials( ) {
        const url = `/api.php`;

        const params = {
            m : 'getVideoTestimonials'
        }

        return HTTP.post( url, params ,'video-testimonials');
    }

    getPendingTestimonials( ) {
        const url = `/api.php`;

        const params = {
            m : 'getPendingTestimonials'
        }

        return HTTP.post( url, params ,'pending-testimonials');
    }

    getDeclinedTestimonials( ) {
        const url = `/api.php`;

        const params = {
            m : 'getDeclinedTestimonials'
        }

        return HTTP.post( url, params ,'declined-testimonials');
    }

    addTestimonial( params ) {
        const url = `/api.php`;


        const {
            name, email, comment, address, rate, fileToUpload,
            captcha
        } = params;
        
        const data = new FormData();
        data.append('m', 'addTestimonial');
        data.append('name', name);
        data.append('email', email);
        data.append('comment', comment);
        data.append('address', address);
        data.append('rate', rate);
        data.append('fileToUpload', fileToUpload);
        data.append('captcha', captcha);

        return HTTP.postUpload( url, data ,'add-testimonials');
    }

    deleteVidTestimonial( id ) {
        const url = `/api.php`;

        const params = {
            id,
            m : 'deleteVidTestimonial'
        }

        return HTTP.post( url, params ,'delete-vid-testimonials');
    }

    addVideoTestimonial( params ) {
        const url = `/api.php`;


        const {
            name, photo,
            video, captcha, id
        } = params;
        
        const data = new FormData();
        data.append('m', 'addVideoTestimonial');
        data.append('id', id);
        data.append('name', name);
        data.append('photo', photo);
        data.append('video', video);
        data.append('captcha', captcha);

        return HTTP.postUpload( url, data ,'add-video-testimonials');
    }

    approveTestimonial( id ) {
        const url = `/api.php`;

        const params = {
            id,
            m : 'approveTestimonial'
        }

        return HTTP.post( url, params ,'approve-testimonials');
    }

    declineTestimonial( id ) {
        const url = `/api.php`;

        const params = {
            id,
            m : 'declineTestimonial'
        }

        return HTTP.post( url, params ,'decline-testimonials');
    }
}

export default new Testimonial();