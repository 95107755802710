import {
    FETCH_USERS, FETCH_USERS_DONE, SET_USERS,
    SET_USER_INFO, DELETE_USER, DELETE_USER_DONE,
    SAVE_USER, SAVE_USER_DONE, CHANGE_PASS,
    CHANGE_PASS_DONE
} from './actions';

import { INIT_STATUS, FETCHING, FETCH_DONE } from './../../../config/constants';

const initState = {
    userInfo    : null,
    users       : {},
    
    fetchStatus : INIT_STATUS,
    updateStatus: INIT_STATUS,
    saveSuccessStatus : null,
    deleteSuccessStatus : null,

    changePassStatus: INIT_STATUS,
    changePassSuccessStatus : null
};

const user = ( state = initState, { type, payload } ) => {
    let retVal = state;

    switch ( type ) {
        case FETCH_USERS: {
            retVal = {
                ...retVal,
                fetchStatus : FETCHING
            };

            break;
        }

        case FETCH_USERS_DONE : {
            retVal = {
                ...retVal,
                fetchStatus : FETCH_DONE
            };

            break;
        }

        case SET_USERS : {
            const { users } = payload;

            retVal = {
                ...retVal,
                users
            };
            break;
        }

        case SET_USER_INFO : {
            const { userInfo } = payload;

            retVal = {
                ...retVal,
                userInfo
            };
            break;
        }

        case DELETE_USER : {
            retVal = {
                ...retVal,
                updateStatus       : FETCHING,
                deleteSuccessStatus: null
            };
            break;
        }

        case DELETE_USER_DONE : {
            const { success } = payload;

            retVal = {
                ...retVal,
                updateStatus : FETCH_DONE,
                deleteSuccessStatus: success
            };
            break;
        }

        case SAVE_USER : {
            retVal = {
                ...retVal,
                updateStatus     : FETCHING,
                saveSuccessStatus: null
            };
            break;
        }

        case SAVE_USER_DONE : {
            const { success } = payload;

            retVal = {
                ...retVal,
                updateStatus     : FETCH_DONE,
                saveSuccessStatus: success
            };
            break;
        }

        case CHANGE_PASS : {
            retVal = {
                ...retVal,
                changePassStatus       : FETCHING,
                changePassSuccessStatus: null
            };
            break;
        }

        case CHANGE_PASS_DONE : {
            const { success } = payload;

            retVal = {
                ...retVal,
                changePassStatus       : FETCH_DONE,
                changePassSuccessStatus: success
            };
            break;
        }

        default : {
            retVal = {
                ...retVal
            };

            break;
        }
    }

    return retVal;
}

export default user;