export const GET_PENDING_APP           = 'getPendingApp',
        GET_PENDING_APP_DONE           = 'getPendingAppDone',
        SET_PENDING_APP                = 'setPendingApp',
        SET_PENDING_APP_TOTAL          = 'setPendingAppTotal',

        GET_FOR_INTERVIEW_APP          = 'getForInterviewApp',
        GET_FOR_INTERVIEW_APP_DONE     = 'getForInterviewAppDone',
        SET_FOR_INTERVIEW_APP          = 'setForInterviewApp',
        SET_FOR_INTERVIEW_APP_TOTAL    = 'setForInterviewAppTotal',

        GET_DONE_INTERVIEW_APP         = 'getDoneInterviewApp',
        GET_DONE_INTERVIEW_APP_DONE    = 'getDoneInterviewAppDone',
        SET_DONE_INTERVIEW_APP         = 'setDoneInterviewApp',
        SET_DONE_INTERVIEW_APP_TOTAL   = 'setDoneInterviewAppTotal',

        GET_FAILED_APP                 = 'getFailedApp',
        GET_FAILED_APP_DONE            = 'getFailedAppDone',
        SET_FAILED_APP                 = 'setFailedApp',
        SET_FAILED_APP_TOTAL           = 'setFailedAppTotal',

        GET_DECLINED_APP               = 'getDeclinedApp',
        GET_DECLINED_APP_DONE          = 'getDeclinedAppDone',
        SET_DECLINED_APP               = 'setDeclinedApp',
        SET_DECLINED_APP_TOTAL         = 'setDeclinedAppTotal',

        GET_HIRED_APP                  = 'getHiredApp',
        GET_HIRED_APP_DONE             = 'getHiredAppDone',
        SET_HIRED_APP                  = 'setHiredApp',
        SET_HIRED_APP_TOTAL            = 'setHiredAppTotal',

        UPDATE_APP_STATUS              = 'updateAppStatus',
        UPDATE_APP_STATUS_DONE         = 'updateAppStatusDone',
        UPDATE_APP_STATUS_DONE_SUCCESS = 'updateAppStatusDoneSuccess';

class ApplicationsActions {
    getPendingApp = params => ({
        type : GET_PENDING_APP,
        payload : {
            params
        }
    })

    getPendingAppDone = params => ({
        type : GET_PENDING_APP_DONE,
        payload : {
            params
        }
    })

    setPendingApp = pending => ({
        type : SET_PENDING_APP,
        payload : {
            pending
        }
    })

    setPendingAppTotal = total => ({
        type : SET_PENDING_APP_TOTAL,
        payload : {
            total
        }
    })


    getForInterviewApp = params => ({
        type : GET_FOR_INTERVIEW_APP,
        payload : {
            params
        }
    })

    getForInterviewAppDone = params => ({
        type : GET_FOR_INTERVIEW_APP_DONE,
        payload : {
            params
        }
    })

    setForInterviewApp = forInterview => ({
        type : SET_FOR_INTERVIEW_APP,
        payload : {
            forInterview
        }
    })

    setForInterviewAppTotal = total => ({
        type : SET_FOR_INTERVIEW_APP_TOTAL,
        payload : {
            total
        }
    })


    getDoneInterviewApp = params => ({
        type : GET_DONE_INTERVIEW_APP,
        payload : {
            params
        }
    })

    getDoneInterviewAppDone = params => ({
        type : GET_DONE_INTERVIEW_APP_DONE,
        payload : {
            params
        }
    })

    setDoneInterviewApp = doneInterview => ({
        type : SET_DONE_INTERVIEW_APP,
        payload : {
            doneInterview
        }
    })

    setDoneInterviewAppTotal = total => ({
        type : SET_DONE_INTERVIEW_APP_TOTAL,
        payload : {
            total
        }
    })


    getFailedApp = params => ({
        type : GET_FAILED_APP,
        payload : {
            params
        }
    })

    getFailedAppDone = params => ({
        type : GET_FAILED_APP_DONE,
        payload : {
            params
        }
    })
    
    setFailedApp = failed => ({
        type : SET_FAILED_APP,
        payload : {
            failed
        }
    })

    setFailedAppTotal = total => ({
        type : SET_FAILED_APP_TOTAL,
        payload : {
            total
        }
    })

    getDeclinedApp = params => ({
        type : GET_DECLINED_APP,
        payload : {
            params
        }
    })

    getDeclinedAppDone = params => ({
        type : GET_DECLINED_APP_DONE,
        payload : {
            params
        }
    })

    setDeclinedApp = declined => ({
        type : SET_DECLINED_APP,
        payload : {
            declined
        }
    })

    setDeclinedAppTotal = total => ({
        type : SET_DECLINED_APP_TOTAL,
        payload : {
            total
        }
    })

    getHiredApp = params => ({
        type : GET_HIRED_APP,
        payload : {
            params
        }
    })

    getHiredAppDone = params => ({
        type : GET_HIRED_APP_DONE,
        payload : {
            params
        }
    })

    setHiredApp = hired => ({
        type : SET_HIRED_APP,
        payload : {
            hired
        }
    })

    setHiredAppTotal = total => ({
        type : SET_HIRED_APP_TOTAL,
        payload : {
            total
        }
    })

    updateAppStatus = params => ({
        type : UPDATE_APP_STATUS,
        payload : {
            params
        }
    })

    updateAppStatusDone = () => ({
        type : UPDATE_APP_STATUS_DONE
    })

    updateAppStatusDoneSuccess = success => ({
        type : UPDATE_APP_STATUS_DONE_SUCCESS,
        payload : {
            success
        }
    })
    
}

export default new ApplicationsActions();