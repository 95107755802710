import {
    GET_PENDING_APP, GET_PENDING_APP_DONE,
    SET_PENDING_APP, SET_PENDING_APP_TOTAL,
    
    GET_FOR_INTERVIEW_APP, GET_FOR_INTERVIEW_APP_DONE,
    SET_FOR_INTERVIEW_APP, SET_FOR_INTERVIEW_APP_TOTAL,

    GET_DONE_INTERVIEW_APP, GET_DONE_INTERVIEW_APP_DONE,
    SET_DONE_INTERVIEW_APP, SET_DONE_INTERVIEW_APP_TOTAL,
    
    GET_FAILED_APP, GET_FAILED_APP_DONE,
    SET_FAILED_APP, SET_FAILED_APP_TOTAL,

    GET_DECLINED_APP, GET_DECLINED_APP_DONE,
    SET_DECLINED_APP, SET_DECLINED_APP_TOTAL,
    
    GET_HIRED_APP, GET_HIRED_APP_DONE,
    SET_HIRED_APP, SET_HIRED_APP_TOTAL,
    
    UPDATE_APP_STATUS,
    UPDATE_APP_STATUS_DONE, UPDATE_APP_STATUS_DONE_SUCCESS
} from './actions';

import { INIT_STATUS, FETCHING, FETCH_DONE } from './../../../config/constants';

const initState = {
    sendhStatus       : INIT_STATUS,
    sendhStatusSuccess: null,
    clearFields       : false,
    pending           : {},
    pendingTotal      : 0,
    forInterview      : {},
    forInterviewTotal : 0,
    doneInterview     : {},
    doneInterviewTotal: 0,
    failed            : {},
    failedTotal       : 0,
    declined          : {},
    declinedTotal     : 0,
    hired             : {},
    hiredTotal        : 0,
    fetchStatus       : INIT_STATUS
};

const careersAdmin = ( state = initState, { type, payload } ) => {
    let retState = state;

    switch ( type ) {
        case UPDATE_APP_STATUS:{
            retState = {
                ...retState,
                sendhStatus : FETCHING
            }
            break;
        }

        case UPDATE_APP_STATUS_DONE: {
            retState = {
                ...retState,
                sendhStatus : FETCH_DONE
            }
            break;
        }

        case GET_PENDING_APP :
        case GET_FOR_INTERVIEW_APP:
        case GET_DONE_INTERVIEW_APP:
        case GET_FAILED_APP:
        case GET_DECLINED_APP:
        case GET_HIRED_APP: {
            retState = {
                ...retState,
                fetchStatus : FETCHING,
                sendhStatusSuccess : false
            }

            break;
        }

        case GET_PENDING_APP_DONE :
        case GET_FOR_INTERVIEW_APP_DONE:
        case GET_DONE_INTERVIEW_APP_DONE:
        case GET_FAILED_APP_DONE:
        case GET_DECLINED_APP_DONE:
        case GET_HIRED_APP_DONE: {
            retState = {
                ...retState,
                fetchStatus : FETCH_DONE,
                sendhStatusSuccess : false
            }

            break;
        }

        case SET_PENDING_APP : {
            const { pending } = payload;

            retState = {
                ...retState,
                pending,
            }

            break;
        }

        case SET_PENDING_APP_TOTAL : {
            const { total } = payload;

            retState = {
                ...retState,
                pendingTotal : total,
            }

            break;
        }

        case SET_FOR_INTERVIEW_APP : {
            const { forInterview } = payload;

            retState = {
                ...retState,
                forInterview,
            }

            break;
        }

        case SET_FOR_INTERVIEW_APP_TOTAL : {
            const { total } = payload;

            retState = {
                ...retState,
                forInterviewTotal : total,
            }

            break;
        }

        case SET_DONE_INTERVIEW_APP : {
            const { doneInterview } = payload;

            retState = {
                ...retState,
                doneInterview,
            }

            break;
        }

        case SET_DONE_INTERVIEW_APP_TOTAL : {
            const { total } = payload;

            retState = {
                ...retState,
                doneInterviewTotal : total,
            }

            break;
        }

        case SET_FAILED_APP : {
            const { failed } = payload;

            retState = {
                ...retState,
                failed
            }

            break;
        }

        case SET_FAILED_APP_TOTAL : {
            const { total } = payload;

            retState = {
                ...retState,
                failedTotal : total
            }

            break;
        }

        case SET_DECLINED_APP : {
            const { declined } = payload;

            retState = {
                ...retState,
                declined
            }

            break;
        }

        case SET_DECLINED_APP_TOTAL : {
            const { total } = payload;

            retState = {
                ...retState,
                declinedTotal : total
            }

            break;
        }

        case SET_HIRED_APP : {
            const { hired } = payload;

            retState = {
                ...retState,
                hired
            }

            break;
        }

        case SET_HIRED_APP_TOTAL : {
            const { total } = payload;

            retState = {
                ...retState,
                hiredTotal : total
            }

            break;
        }

        case UPDATE_APP_STATUS_DONE_SUCCESS : {
            const { success } = payload;

            retState = {
                ...retState,
                sendhStatusSuccess : success
            }

            break;
        }

        default : {
            retState = {
                ...retState
            }

            break;
        }
    }

    return retState;
}

export default careersAdmin;