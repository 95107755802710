export const SET_UPLOAD_PROGRESS = 'setUploadProgress';

class Progressctions {
    setUploadProgress = progress => ({
        type    : SET_UPLOAD_PROGRESS,
        payload : {
            progress
        }
    })
}

export default new Progressctions();