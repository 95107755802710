import {
    all, takeLatest, call, put, select
} from 'redux-saga/effects';
import ApplicationsActions, {
    GET_PENDING_APP,
    GET_FOR_INTERVIEW_APP, GET_DONE_INTERVIEW_APP,
    GET_FAILED_APP, GET_DECLINED_APP,
    GET_HIRED_APP, UPDATE_APP_STATUS
} from './actions';
import Careers from '../../../api/Careers';
import AlertActions from './../../alert/action';
import SessionActions from './../../session/actions';

function* logout() {
    yield put( SessionActions.doLogout() );
}

function* getPending({ payload }) {
    try {

        const { params } = payload;
        const { success = true, message = '', data = [], total = 0} = yield call ([ Careers, Careers.getPendingApplications ], params );

        yield put( ApplicationsActions.getPendingAppDone() );

        if( !success ) {
            throw message;
        }

        const applicants = data.reduce(( accumulator, applicant )=>({
            ...accumulator,
            [ applicant.id ] : {
                ...applicant
            }
        }),{});

        yield put( ApplicationsActions.setPendingApp( applicants ) );
        yield put( ApplicationsActions.setPendingAppTotal( total ) );

    } catch ( error ) {

        yield put( ApplicationsActions.getPendingAppDone() );
        yield put( AlertActions.addError( error ) );

        if( error === 'Unauthorized') {
            yield call( logout );
        }
    }
}

function* getForInterview({ payload }) {
    try {

        const { params } = payload;
        const { success = true, message = '', data = [], total = 0} = yield call ([ Careers, Careers.getForIterviewApplications ], params );

        yield put( ApplicationsActions.getForInterviewAppDone() );

        if( !success ) {
            throw message;
        }

        const applicants = data.reduce(( accumulator, applicant )=>({
            ...accumulator,
            [ applicant.id ] : {
                ...applicant
            }
        }),{});

        yield put( ApplicationsActions.setForInterviewApp( applicants ) );
        yield put( ApplicationsActions.setForInterviewAppTotal( total ) );

    } catch ( error ) {
        yield put( ApplicationsActions.getForInterviewAppDone() );
        yield put( AlertActions.addError( error ) );

        if( error === 'Unauthorized') {
            yield call( logout );
        }
    }
}

function* getDoneInterview({ payload }) {
    try {

        const { params } = payload;
        const { success = true, message = '', data = [], total = 0} = yield call ([ Careers, Careers.getDoneIterviewApplications ], params );

        yield put( ApplicationsActions.getDoneInterviewAppDone() );

        if( !success ) {
            throw message;
        }

        const applicants = data.reduce(( accumulator, applicant )=>({
            ...accumulator,
            [ applicant.id ] : {
                ...applicant
            }
        }),{});

        yield put( ApplicationsActions.setDoneInterviewApp( applicants ) );
        yield put( ApplicationsActions.setDoneInterviewAppTotal( total ) );

    } catch ( error ) {
        yield put( ApplicationsActions.getDoneInterviewAppDone() );
        yield put( AlertActions.addError( error ) );

        if( error === 'Unauthorized') {
            yield call( logout );
        }
    }
}

function* getFailed({ payload }) {
    try {

        const { params } = payload;
        const { success = true, message = '', data = [], total = 0} = yield call ([ Careers, Careers.getFailedApplications ], params );

        yield put( ApplicationsActions.getFailedAppDone() );

        if( !success ) {
            throw message;
        }

        const applicants = data.reduce(( accumulator, applicant )=>({
            ...accumulator,
            [ applicant.id ] : {
                ...applicant
            }
        }),{});

        yield put( ApplicationsActions.setFailedApp( applicants ) );
        yield put( ApplicationsActions.setFailedAppTotal( total ) );

    } catch ( error ) {
        yield put( ApplicationsActions.getFailedAppDone() );
        yield put( AlertActions.addError( error ) );

        if( error === 'Unauthorized') {
            yield call( logout );
        }
    }
}

function* getDeclined({ payload }) {
    try {

        const { params } = payload;
        const { success = true, message = '', data = [], total = 0} = yield call ([ Careers, Careers.getDeclinedApplications ], params );

        yield put( ApplicationsActions.getDeclinedAppDone() );

        if( !success ) {
            throw message;
        }

        const applicants = data.reduce(( accumulator, applicant )=>({
            ...accumulator,
            [ applicant.id ] : {
                ...applicant
            }
        }),{});

        yield put( ApplicationsActions.setDeclinedApp( applicants ) );
        yield put( ApplicationsActions.setDeclinedAppTotal( total ) );

    } catch ( error ) {
        yield put( ApplicationsActions.getDeclinedAppDone() );
        yield put( AlertActions.addError( error ) );

        if( error === 'Unauthorized') {
            yield call( logout );
        }
    }
}

function* getHired({ payload }) {
    try {

        const { params } = payload;
        const { success = true, message = '', data = [], total = 0} = yield call ([ Careers, Careers.getHiredApplications ], params );

        yield put( ApplicationsActions.getHiredAppDone() );

        if( !success ) {
            throw message;
        }

        const applicants = data.reduce(( accumulator, applicant )=>({
            ...accumulator,
            [ applicant.id ] : {
                ...applicant
            }
        }),{});

        yield put( ApplicationsActions.setHiredApp( applicants ) );
        yield put( ApplicationsActions.setHiredAppTotal( total ) );

    } catch ( error ) {
        yield put( ApplicationsActions.getHiredAppDone() );
        yield put( AlertActions.addError( error ) );

        if( error === 'Unauthorized') {
            yield call( logout );
        }
    }
}

function* updateAppStatus({payload}) {
    try {

        const { params } = payload;
        const { success = true, message = '', data = []} = yield call ([ Careers, Careers.updateApplicationStatus ], params );

        yield put( ApplicationsActions.updateAppStatusDone() );

        if( !success ) {
            throw message;
        }

        yield put( ApplicationsActions.updateAppStatusDoneSuccess( true ) );

        yield call( getPending );
        yield call( getForInterview );
        yield call( getDoneInterview );
        yield call( getFailed );
        yield call( getDeclined );
        yield call( getHired );

    } catch ( error ) {
        yield put( ApplicationsActions.getHiredDone() );
        yield put( ApplicationsActions.updateAppStatusDoneSuccess( false ) );
        yield put( AlertActions.addError( error ) );

        if( error === 'Unauthorized') {
            yield call( logout );
        }
    }
}

function* watchCareersAdmin() {
    yield takeLatest( GET_PENDING_APP, getPending );
    yield takeLatest( GET_FOR_INTERVIEW_APP, getForInterview );
    yield takeLatest( GET_DONE_INTERVIEW_APP, getDoneInterview );
    yield takeLatest( GET_FAILED_APP, getFailed );
    yield takeLatest( GET_DECLINED_APP, getDeclined );
    yield takeLatest( GET_HIRED_APP, getHired );
    yield takeLatest( UPDATE_APP_STATUS, updateAppStatus );
}

export default function* careersAdminRoot() {
    yield all([
        call( watchCareersAdmin )
    ]);
}