export const SEND_INQUIRY    = 'sendInquiry',
        SEND_INQUIRY_DONE    = 'sendInquiryDone',
        CLEAR_CONTACT_FIELDS = 'clearContactFields';

class ContactUsActions {
    sendInquiry = ( email, comment, contact ) => ({
        type    : SEND_INQUIRY,
        payload : {
            email, comment, contact
        }
    })

    sendInquiryDone = () => ({
        type : SEND_INQUIRY_DONE
    })

    clearContactFields = clear => ({
        type   : CLEAR_CONTACT_FIELDS,
        payload: {
            clear
        }
    })
}

export default new ContactUsActions();